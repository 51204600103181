.App {
  text-align: center;
}

.dot {
  height: 3px;
  width: 20px;
  border-radius: 3px;
}
.thumbnails {
  margin: 0;
  align-items: flex-start;
}
.thumbnail {
  margin: 0;
  min-height: 100px;
  max-height: 100px;
}